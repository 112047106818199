import { Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import { injectIntl } from "react-intl";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import imageCompression from "browser-image-compression";

import { catchUIError, uploadPostWeight } from "../../_redux/pets";
import jsonHelper from "../../../../../_artifact/helpers/jsonHelper";
import { translate } from "../../../../../_artifact/i18n";
import ErrorMessage from "../../../../../_artifact/components/forms/ErrorMessage";
import Modal from "../../../../../_artifact/components/Modal";
import config from "../../../../config";
import { trackingLog } from "../../../Tracking/_redux/tracking";
import { format } from "date-fns";
import RedirectModel from "../../../../../_artifact/components/RedirectModel";

const initialValues = {
  product_post_wt: "",
  product_post_img: "",
};

function PostWeight({ intl, history }) {
  const [imageURL, setImageURL] = useState("");
  const [fileName, setFileName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const imgRef = useRef(null);
  let count = useRef(0);
  const selectedTest = useRef({});
  const validationSchema = Yup.object().shape({
    product_post_img: Yup.mixed()
      .required(translate("APP.VALIDATION.REQUIRED_FIELD", intl))
      .test(
        "fileFormat",
        translate("APP.VALIDATION.UNSUPPORTED_FILE_TYPE", intl),
        (value) => value && value.type.includes("image")
      ),
    product_post_wt: Yup.number()
      .typeError(translate("APP.VALIDATION.INVALID_VALUE", intl))
      .min(0, translate("APP.VALIDATION.INVALID_VALUE", intl))
      .required(translate("APP.VALIDATION.REQUIRED_FIELD", intl)),
  });

  const { trackingPayload } = useSelector(
    (state) => state.tracking,
    shallowEqual
  );
  useEffect(() => {
    const jsonString = localStorage.getItem("selectedTest");
    const myObject = JSON.parse(jsonString);
    selectedTest.current = myObject;
    if (!myObject?.test_schedule_id) {
      history.replace("/testing/codes");
    }
    return () => {
      if (imgRef.current) URL.revokeObjectURL(imgRef.current);
    };
  }, []);

  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    var preWeight = localStorage.getItem("preWeight");
    if (+values.product_post_wt > +preWeight) {
      setIsOpen(true);
      return;
    }

    values.test_schedule_id = selectedTest.current.test_schedule_id;
    values.panelist_id = selectedTest.current.panelist_id;
    values.product_id = selectedTest.current.product_id;

    imgRef.current = imageURL;
    // console.log(values);

    count.current = count.current + 1;
    const api = () => {
      dispatch(uploadPostWeight(jsonHelper.jsonToForm(values)))
        .then(() => {
          toast.success(
            translate("TESTING.TOAST.WEIGHT_LEFTOVER_SUCCESS", intl)
          );
          dispatch(
            trackingLog({
              ...trackingPayload,
              final_weight: values.product_post_wt,
              end_image_url: imageURL,
            })
          );
          history.replace("/testing/finish");
        })
        .catch((error) => {
          dispatch(
            trackingLog({
              ...trackingPayload,
              api_log:
                error["stack"] +
                " | PreWeight " +
                format(new Date(), "yyyy-MM-dd HH:mm:ss") +
                " |" +
                error.message,
              final_weight: values.product_post_wt,
              end_image_url: imageURL,
            })
          );

          // retry++;
          // if (count >=2) {
          //   api();
          // }
          if (count.current >= 2) {
            setOpen(true);
            alert("Please take the screenshots of this." + error);
          }
          error.clientMessage = translate(
            "TESTING.TOAST.WEIGHT_LEFTOVER_FAILED",
            intl
          );
          dispatch(catchUIError(error));
        });
    };
    api();
  };

  const handleCapture = async (e, setFieldValue) => {
    if (e.target.files.length === 0) {
      setFieldValue("product_post_img", "");
      if (imageURL) URL.revokeObjectURL(imageURL);
      setImageURL("");
      return;
    }

    const file = e.target.files[0];

    const options = {
      maxSizeMB: config.file.maxSizeMB,
      maxWidthOrHeight: config.file.maxWidthOrHeight,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);

      setFieldValue("product_post_img", compressedFile);
      if (imageURL) URL.revokeObjectURL(imageURL);
      setImageURL(URL.createObjectURL(compressedFile));
      setFileName(compressedFile.name);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="inner-page">
      <Modal
        isOpen={isOpen}
        action={translate("TESTING.BUTTON.ENTER_AGAIN", intl)}
        title={translate("TESTING.GENERAL.LEFTOVER_GREATER_THAN_OFFERED", intl)}
        icon="icon-circle-exclamation text-danger font-200"
        onClose={() => setIsOpen(false)}
      />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, handleSubmit, errors, touched }) => (
          <div className="test-box">
            <h3 className="test-box-title desktop-only">
              <i className="icon-capture"></i>{" "}
              {translate("TESTING.GENERAL.CAPTURE_WEIGHT_LEFTOVER", intl)}
            </h3>
            <div className="capture-wrap">
              {imageURL && (
                <div className="caputred-image-wrap">
                  <div className="caputred-image">
                    <img src={imageURL} alt="" />
                  </div>
                  <div className="caputred-image-info">
                    <span className="file-name">{fileName}</span>
                    <span className="reupload">
                      {translate("TESTING.GENERAL.RE_UPLOAD", intl)}
                      <i className="icon-reload"></i>
                      <input
                        name="product_pre_img"
                        type="file"
                        capture="environment"
                        accept="image/*"
                        onChange={(e) => handleCapture(e, setFieldValue)}
                      />
                    </span>
                  </div>
                </div>
              )}
              {!imageURL && (
                <div className="capture-inner">
                  <div className="c-left">
                    <h3 className="test-box-title mobile-only">
                      <i className="icon-capture"></i>{" "}
                      {translate("TESTING.GENERAL.CAPTURE_WEIGHT", intl)}
                    </h3>
                    <div className="upload text-center desktop-only">
                      <img src="/assets/images/upload.svg" alt="" />
                      <p>
                        {translate("TESTING.GENERAL.DRAN_AND_DROP", intl)}{" "}
                        <br />
                        {translate("APP.GENERAL.OR", intl)}
                      </p>
                      <button className="btn btn-outline-primary btn-h-40 use-camera">
                        {translate("TESTING.GENERAL.BROWSE", intl)}
                        <input
                          name="product_post_img"
                          type="file"
                          capture="environment"
                          accept="image/*"
                          onChange={(e) => handleCapture(e, setFieldValue)}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="c-right">
                    <p>
                      {translate("TESTING.GENERAL.CLICK_PICTURE", intl)}{" "}
                      <strong>
                        {translate("TESTING.GENERAL.LEFTOVER_FOOD", intl)}
                      </strong>{" "}
                      {translate("TESTING.GENERAL.TO_THE_PET", intl)}
                    </p>
                    <div className="c-instruction">
                      <p>
                        {translate("TESTING.GENERAL.PICTURE_SHOULD_SHOW", intl)}{" "}
                        <br />
                        &nbsp; 1.{" "}
                        <strong>
                          {translate("TESTING.GENERAL.FOOD", intl)}
                        </strong>{" "}
                        {translate("TESTING.GENERAL.IN_THE_BOWL", intl)}
                        <br />
                        &nbsp; 2.{" "}
                        <strong>
                          {translate("TESTING.GENERAL.BOWL", intl)}
                        </strong>{" "}
                        {translate(
                          "TESTING.GENERAL.SHOULD_BE_ON_THE_SCALE",
                          intl
                        )}
                        <br />
                        &nbsp; 3.{" "}
                        <strong>
                          {translate("TESTING.GENERAL.WEIGHT", intl)}
                        </strong>{" "}
                        {translate(
                          "TESTING.GENERAL.CLEARLY_INDICATED_ON_THE_SCALE",
                          intl
                        )}
                      </p>
                    </div>
                    <button className="btn btn-outline-primary btn-h-40 btn-block use-camera mobile-only">
                      {translate("TESTING.GENERAL.USE_CAMERA", intl)}
                      <input
                        name="product_post_img"
                        type="file"
                        capture="environment"
                        accept="image/*"
                        onChange={(e) => handleCapture(e, setFieldValue)}
                      />
                    </button>
                  </div>
                </div>
              )}
              <ErrorMessage
                error={errors.product_post_img}
                visible={touched.product_post_img}
              />
            </div>
            <div className="form-group inline-label enter-weight">
              <label>
                {translate(
                  "TESTING.GENERAL.ENTER_THE_WEIGTH_FOOD_LEFTOVER",
                  intl
                )}
              </label>
              <div>
                <input
                  name="product_post_wt"
                  type="number"
                  onWheel={(e) => {
                    e.currentTarget.blur();
                  }}
                  className="form-control"
                  placeholder="gms"
                  onChange={(e) =>
                    setFieldValue("product_post_wt", e.target.value)
                  }
                />
                {
                  <ErrorMessage
                    error={errors.product_post_wt}
                    visible={touched.product_post_wt}
                  />
                }
                <div className="invalid-feedback" style={{ display: "none" }}>
                  ABC
                </div>
              </div>
            </div>
            <div className="text-center action-btn">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                onClick={handleSubmit}
              >
                {translate("TESTING.BUTTON.NEXT", intl)}
              </button>
            </div>
          </div>
        )}
      </Formik>
      <RedirectModel history={history} isOpen={open} />
    </div>
  );
}

export default injectIntl(PostWeight);

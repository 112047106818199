/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_artifact/layout";
import ForgotPassword from "./forgot-password/ForgotPassword";
import Login from "./Login";
import ResetPassword from "./reset-password/ResetPassword";
import ResetPasswordOtp from "./reset-password/ResetPasswordOtp";

export function AuthPage() {
  return (
    <>
      <Switch>
        <ContentRoute path="/auth/login" component={Login} />
        <ContentRoute path="/auth/forgot-password" component={ForgotPassword} />
        <ContentRoute path="/auth/verify-otp" component={ResetPasswordOtp} />
        <ContentRoute path="/auth/reset-password" component={ResetPassword} />
      </Switch>
    </>
  );
}

import React, { useRef } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import * as Yup from "yup";

import ErrorMessage from "../../../../../_artifact/components/forms/ErrorMessage";
import { fetchForgotPassword, fetchVerifyOtp, updateAuthError } from "../..";
import jsonHelper from "../../../../../_artifact/helpers/jsonHelper";
import { toast } from "react-toastify";

const initialValues = {
  otp: "",
};

function ResetPasswordOtp({ history }) {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { forgotUser } = useSelector(
    (state) => ({ forgotUser: state.auth.forgotUser }),
    shallowEqual
  );

  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .matches(/^[0-9]+$/, "Invalid value")
      .max(10, "Invalid value")
      .required("Required Field"),
  });

  const resetForm = () => {
    if (!formikRef.current) return;
    formikRef.current.resetForm();
  };

  const handleSubmit = (values) => {
    console.log(values);
    dispatch(
      fetchVerifyOtp(
        jsonHelper.jsonToForm({
          authId: forgotUser.authId,
          email_id: forgotUser.email,
          otp: values.otp,
        })
      )
    )
      .then(() => {
        history.replace("/auth/reset-password");
      })
      .catch((error) => {
        resetForm();
        if (error.response && error.response.data)
          error.clientMessage = error.response.data.message;
        else error.clientMessage = "Can't verify OTP";
        dispatch(updateAuthError(error));
      });
  };

  const resendOtp = () => {
    dispatch(
      fetchForgotPassword(jsonHelper.jsonToForm({ email: forgotUser.email }))
    )
      .then(() => {
        toast.success("OTP sent.");
      })
      .catch((error) => {
        error.clientMessage = "Can't send OTP.";
        dispatch(updateAuthError(error));
      });
  };

  return (
    <div className="auth-wrap">
      <div className="auth-box">
        <h2 className="auth-title desktop-only">Reset Password</h2>

        <div className="page-title-inner mobile-only">
          <Link to="/profile">
            <img src="/assets/images/left-arrow.svg" alt="" />
          </Link>
          <h2>Reset Password</h2>
        </div>

        <p className="semi-bold">
          OTP sent to your{" "}
          <a href={`mailto:${forgotUser.email}`} className="link primary">
            {forgotUser.email}
          </a>{" "}
          please enter it to reset your password.
        </p>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            errors,
            touched,
            status,
          }) => (
            <form className="form-wrap">
              <div className="form-body">
                <div className="form-group otp">
                  <div className="otp-inner">
                    <input
                      name="otp"
                      type="text"
                      className="otp-input"
                      // maxLength="4"
                      value={values.otp}
                      onChange={(e) => setFieldValue("otp", e.target.value)}
                    />
                  </div>
                  {/* <div className="otp-border">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div> */}
                </div>
                <ErrorMessage error={errors.otp} visible={touched.otp} />
              </div>
              <div className="form-footer">
                <button
                  className="btn btn-primary btn-block"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit OTP
                </button>
              </div>
            </form>
          )}
        </Formik>
        <p className="text-center resend-top">
          Didn’t recieve OTP?{" "}
          <a href="#" className="link primary" onClick={resendOtp}>
            Resend
          </a>
        </p>
      </div>
    </div>
  );
}

export default ResetPasswordOtp;

import React, { useEffect, useMemo, useState } from "react";
import { injectIntl } from "react-intl";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  fetchPetNotifs,
  fetchPets,
  selectPet,
  selectPetNotif,
  selectTest,
} from "../../_redux/pets";
import {
  fetchPreviousTests,
  selectPreviousTest,
} from "../../_redux/previousTests";

import Modal from "react-modal";
import dateHelper from "../../../../../_artifact/helpers/dateHelper";
import { selectPetRewards } from "../../../Rewards/_redux/rewards";
import { translate } from "../../../../../_artifact/i18n/Artifacti18n";
import config from "../../../../config";
import { TestStatusTitles } from "../TesingUIHelper";
import { useAppUIContext } from "../../../../AppUIContext";
import { fetchFaqs } from "../../../Support/_redux/supports";
import { processFaqs } from "../../../Support/pages/SupportUIHelper";
import Refresh from "../../../Auth/pages/Refresh";
import { trackingLog } from "../../../Tracking/_redux/tracking";
import { resetSelectedTrial } from "../../../../utils";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "1.5rem 1rem",
    transform: "translate(-50%, -50%)",
  },
};

let mounted = 0;
function PetSelection({ intl, history }) {
  const appUIContext = useAppUIContext();
  const appUIProps = useMemo(() => {
    return {
      pets: appUIContext.pets,
      setPets: appUIContext.setPets,
      setReadReview: appUIContext.setReadReview,
      getReadReview: appUIContext.getReadReview,
      tab: appUIContext.tab,
      setTab: appUIContext.setTab,
      rejected: appUIContext.rejected,
      setRejected: appUIContext.setRejected,
    };
  }, [appUIContext]);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [badge, setBadge] = useState(0);
  const [questions, setQuestions] = useState(null);
  const [previousTests, setPreviousTests] = useState(null);

  const dispatch = useDispatch();
  const { state } = useSelector((state) => ({ state }), shallowEqual);
  const { trackingPayload } = useSelector(
    (state) => state.tracking,
    shallowEqual
  );
  const {
    panelist_email_id: email,
    first_name,
    consent_flag: isConsent,
  } = state.auth.user;
  const { faqs } = state.supports.entities;

  const { entities, selectedPet, selectedPetNotif } = state.pets;
  const {
    entities: { rewardPoints },
    selectedPetRewards,
  } = state.rewards;
  const { panelist_pets, petNotifs } = entities;

  const {
    currentTestSchedule: currentTests,
    upcomingTestSchedule: upcomingTests,
  } = selectedPet;
  const previousTestsInStore = state.previousTests.entities;

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const sortDescNewReview = (tests) => {
    tests.sort((a, b) => {
      if (a.new_review < b.new_review) return 1;
      if (a.new_review > b.new_review) return -1;
      return 0;
    });

    return tests;
  };

  const sortDescRejectedAndNewReview = (tests) => {
    const rejectedTests = tests.filter(
      ({ score }) => score === TestStatusTitles.rejected
    );
    const nonRejectedTests = tests.filter(
      ({ score }) => score !== TestStatusTitles.rejected
    );

    const sortedRejectedTests = sortDescNewReview(rejectedTests);
    const sortedNonRejectedTests = sortDescNewReview(nonRejectedTests);
    sortedRejectedTests.push(...sortedNonRejectedTests);

    return sortedRejectedTests;
  };

  // sort by highlight
  useEffect(() => {
    mounted++;
    if (!previousTestsInStore) return;
    const tests = JSON.parse(JSON.stringify(previousTestsInStore));
    if (appUIProps.rejected)
      setPreviousTests(sortDescRejectedAndNewReview(tests));
    else setPreviousTests(sortDescNewReview(tests));

    if (mounted % 2 === 0) appUIProps.setRejected(false);
  }, [previousTestsInStore]);
  useEffect(() => {
    resetSelectedTrial();
  }, []);
  // Selecting a pet on page load automatically.
  useEffect(() => {
    if (!selectedPet.panelist_id)
      if (panelist_pets)
        if (panelist_pets.length > 0) dispatch(selectPet(panelist_pets[0]));
    dispatch(
      trackingLog({
        ...trackingPayload,
        pet_name: state.pets.selectedPet.pet_name,
      })
    );
  }, [dispatch, panelist_pets]);

  useEffect(() => {
    if (rewardPoints) {
      const rewardPoint = rewardPoints.find(
        ({ panelist_id }) => panelist_id === selectedPet.panelist_id
      );
      dispatch(selectPetRewards(rewardPoint));
    }
  }, [selectedPet]);

  // Fetching all details
  useEffect(() => {
    const panelist_email = { panelist_email: email };
    dispatch(fetchPets(panelist_email));
    // dispatch(fetchPetNotifs(panelist_email));
    // dispatch(fetchRewardPoints(panelist_email));
    // dispatch(fetchFaqs());
  }, [dispatch]);

  useEffect(() => {
    const processedFaqs = processFaqs(faqs);
    setQuestions(processedFaqs?.filter((faq, index) => index < 2));
  }, [faqs]);

  // Opening notification popup
  useEffect(() => {
    if (previousTests) {
      setBadge(previousTests.filter(({ new_review }) => new_review > 0).length);
    }

    if (!petNotifs) {
      dispatch(selectPetNotif(null));
      closeModal();
      return;
    }

    const petNotif = petNotifs.find(
      (petNotif) => petNotif.panelist_id === selectedPet.panelist_id
    );

    if (!petNotif) {
      dispatch(selectPetNotif(null));
      closeModal();
      return;
    }
    dispatch(selectPetNotif(petNotif));

    const selectedPreviousTest = previousTests?.find(
      (previousTest) =>
        previousTest.test_schedule_id === petNotif.test_schedule_id
    );

    if (selectedPreviousTest)
      dispatch(selectPreviousTest(selectedPreviousTest));

    if (appUIProps.getReadReview(selectedPet.panelist_id)) return;
    openModal();
    appUIProps.setReadReview(selectedPet.panelist_id);
  }, [petNotifs, selectedPet, previousTests]);

  // Fetching previous tests
  useEffect(() => {
    // if (tab === 1 || !previousTests)
    if (selectedPet.panelist_id)
      dispatch(fetchPreviousTests({ panelist_id: selectedPet.panelist_id }));
  }, [dispatch, appUIProps.tab, selectedPet]);

  const greetings = () => {
    const date = dateHelper.now();
    if (
      date.getHours() >= config.morningHours &&
      date.getHours() < config.afternoonHours
    )
      return translate("APP.GREETINGS.GOOD_MORNING", intl);
    if (
      date.getHours() >= config.afternoonHours &&
      date.getHours() < config.eveningHours
    )
      return translate("APP.GREETINGS.GOOD_AFTERNOON", intl);
    return translate("APP.GREETINGS.GOOD_EVENING", intl);
  };

  const handleViewFeedback = () => {
    if (!selectedPetNotif) return;
    if (selectedPet.panelist_id)
      dispatch(fetchPreviousTests({ panelist_id: selectedPet.panelist_id }));
    closeModal();
    if (selectedPetNotif.review_count === 1) history.push("/testing/detail");
    else {
      appUIProps.setTab(config.tabs.previous);
      history.push("/testing");
    }
  };

  return (
    <>
      {/* <Refresh /> */}
      <div className="inner-page landing">
        <h1>
          {greetings()}, {first_name}!
        </h1>
        <div className="pets-wrap-home">
          <h3>{translate("TESTING.GENERAL.YOUR_PET", intl)}</h3>
          <ul className="pet-lists">
            {panelist_pets?.map((pet, index) => (
              <li key={index}>
                <input
                  type="radio"
                  name="pet"
                  value=""
                  id={pet.panelist_id}
                  onChange={() => dispatch(selectPet(pet))}
                  checked={pet.panelist_id === selectedPet.panelist_id}
                />
                <label htmlFor={pet.panelist_id}>{pet.pet_name}</label>
                {pet.currentTestSchedule.length > 0 && (
                  <span className="badge danger">
                    {pet.currentTestSchedule.length}
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>

        <div className="pets-dashboard">
          <div className="p-dashboard-left">
            {currentTests && currentTests.length > 0 && (
              <div className="p-upcoming-test">
                <h3 className="d-icon-title">
                  <i className="icon-test desktop-only"></i>{" "}
                  {translate("TESTING.GENERAL.CURRENT_TESTS", intl)}
                </h3>
                <div className="p-upcoming-test-scroll">
                  <ul className="test-lists live-test arrow">
                    {currentTests?.map((currentTest, index) => (
                      <li
                        key={index}
                        className="test-item"
                        onClick={() => {
                          dispatch(selectTest(currentTest));
                          const copyTest = { ...currentTest };
                          copyTest["panelist_id"] = selectedPet.panelist_id;
                          copyTest["pet_name"] = selectedPet.pet_name;
                          copyTest["species"] = selectedPet.species;
                          const jsonString = JSON.stringify(copyTest);
                          localStorage.setItem("selectedTest", jsonString);
                          history.push("/testing/codes");
                        }}
                      >
                        <div className="test-image">
                          <img src="/assets/images/product.png" alt="" />
                        </div>
                        <div className="test-content">
                          <h4>{translate("APP.GREETINGS.TODAY", intl)}</h4>
                          <p>
                            {" "}
                            {translate("TESTING.GENERAL.CODE", intl)}:{" "}
                            {currentTest.product_id}
                          </p>
                          <p>
                            {translate("TESTING.GENERAL.TEST", intl)}:{" "}
                            {currentTest.test_name}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <hr />
              </div>
            )}
            <div className="p-previous-test">
              <Tabs
                selectedIndex={appUIProps.tab}
                onSelect={(tabIndex) => appUIProps.setTab(tabIndex)}
              >
                <TabList>
                  <Tab>{translate("TESTING.GENERAL.UPCOMING_TESTS", intl)}</Tab>
                  <Tab>
                    {translate("TESTING.GENERAL.PREVIOUS_TESTS", intl)}{" "}
                    {badge > 0 && <span className="badge danger">{badge}</span>}
                  </Tab>
                </TabList>
                <TabPanel>
                  {/* <CustomScroll heightRelativeToParent> */}

                  <ul className="test-lists">
                    {upcomingTests?.map((upcomingTest, index) => (
                      <li key={index} className="test-item">
                        <div className="test-image">
                          <img src="/assets/images/product.png" alt="" />
                        </div>
                        <div className="test-content">
                          <h4>{upcomingTest.test_date}</h4>
                          <p>
                            {" "}
                            {translate("TESTING.GENERAL.CODE", intl)}:{" "}
                            {upcomingTest.product_id}
                          </p>
                          <p>
                            {translate("TESTING.GENERAL.TEST", intl)}:{" "}
                            {upcomingTest.test_name}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>

                  {/* </CustomScroll> */}
                  {(!upcomingTests || upcomingTests.length === 0) && (
                    <div className="test-empty">
                      <div className="test-empty-message">
                        <img src="/assets/images/empty.svg" alt="empty" />
                        <p>
                          {translate("TESTING.GENERAL.NO_UPCOMING_TESTS", intl)}
                        </p>
                      </div>
                    </div>
                  )}
                </TabPanel>
                <TabPanel>
                  {/* <CustomScroll heightRelativeToParent> */}
                  <ul className="test-lists arrow">
                    {previousTests?.map((previousTest, index) => (
                      <li
                        key={index}
                        className={`test-item ${
                          previousTest.new_review > 0 && "color-bg"
                        }`}
                        onClick={() => {
                          history.push("/testing/detail");
                          dispatch(selectPreviousTest(previousTest));
                        }}
                      >
                        <div className="test-image">
                          <img src="/assets/images/product.png" alt="" />
                        </div>
                        <div className="test-content">
                          <h4>
                            {previousTest.test_date}
                            {previousTest.score ===
                              TestStatusTitles.pending && (
                              <span className="test-status pending">
                                {translate("TESTING.GENERAL.PENDING", intl)}
                              </span>
                            )}
                            {previousTest.score ===
                              TestStatusTitles.approved && (
                              <span className="test-status success">
                                {translate("TESTING.GENERAL.APPROVED", intl)}
                              </span>
                            )}
                            {previousTest.score ===
                              TestStatusTitles.rejected && (
                              <span className="test-status rejected">
                                {translate("TESTING.GENERAL.REJECTED", intl)}
                              </span>
                            )}
                          </h4>
                          <p>
                            {" "}
                            {translate("TESTING.GENERAL.CODE", intl)}:{" "}
                            {previousTest.product_id}
                          </p>
                          <p>
                            {translate("TESTING.GENERAL.TEST", intl)}:{" "}
                            {previousTest.test_name}
                          </p>
                          <p>
                            {translate("TESTING.GENERAL.COMMENT", intl)}:{" "}
                            <strong>{previousTest.feedback}</strong>
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                  {/* </CustomScroll> */}
                  {(!previousTests || previousTests.length === 0) && (
                    <div className="test-empty">
                      <div className="test-empty-message">
                        <img src="/assets/images/empty.svg" alt="empty" />
                        <p>
                          {translate("TESTING.GENERAL.NO_PREVIOUS_TESTS", intl)}
                        </p>
                      </div>
                    </div>
                  )}
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen && isConsent === "Y"}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <button onClick={closeModal} className="close">
          <img src="/assets/images/icon-close.svg" alt="" />
        </button>
        {selectedPetNotif?.review_count > 1 && (
          <h4>
            {translate("TESTING.GENERAL.RECEIVED_REVIEWS", intl, {
              number: selectedPetNotif?.review_count,
            })}
          </h4>
        )}
        {selectedPetNotif?.review_count === 1 && (
          <h4>
            {translate("TESTING.GENERAL.YOUR_TEST_HAS_BEEN", intl)}{" "}
            {selectedPetNotif?.score === TestStatusTitles.approved && (
              <span style={{ color: "green" }}>
                {translate("TESTING.GENERAL.APPROVED", intl)}!
              </span>
            )}
            {selectedPetNotif?.score === TestStatusTitles.rejected && (
              <span style={{ color: "tomato" }}>
                {translate("TESTING.GENERAL.REJECTED", intl)}!
              </span>
            )}{" "}
            {translate("TESTING.GENERAL.CLICK_TO_VIEW_FEEDBACK", intl)}
          </h4>
        )}
        {selectedPetNotif?.review_count === 1 && (
          <p>{selectedPetNotif?.feedback}</p>
        )}

        <button
          className="btn btn-primary btn-block"
          onClick={handleViewFeedback}
        >
          {selectedPetNotif?.review_count === 1
            ? translate("TESTING.BUTTON.VIEW_FEEDBACK", intl)
            : translate("TESTING.BUTTON.VIEW_ALL", intl)}
        </button>
      </Modal>
    </>
  );
}

export default injectIntl(PetSelection);

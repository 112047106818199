import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import imageCompression from "browser-image-compression";

import { catchUIError, uploadPreWeight } from "../../_redux/pets";
import jsonHelper from "../../../../../_artifact/helpers/jsonHelper";
import { translate } from "../../../../../_artifact/i18n";
import ErrorMessage from "../../../../../_artifact/components/forms/ErrorMessage";
import config from "../../../../config";

import { trackingLog } from "../../../Tracking/_redux/tracking";
import { format } from "date-fns";
import RedirectModel from "../../../../../_artifact/components/RedirectModel";

const initialValues = {
  product_pre_wt: "",
  product_pre_img: "",
};

function PreWeight({ intl, history }) {
  const [imageURL, setImageURL] = useState("");
  const [fileName, setFileName] = useState("");
  const [open, setOpen] = useState(false);
  const selectedTest = useRef({});
  const imgRef = useRef(null);
  const validationSchema = Yup.object().shape({
    product_pre_img: Yup.mixed()
      .required(translate("APP.VALIDATION.REQUIRED_FIELD", intl))
      .test(
        "fileFormat",
        translate("APP.VALIDATION.UNSUPPORTED_FILE_TYPE", intl),
        (value) => value && value.type.includes("image")
      ),
    product_pre_wt: Yup.number()
      .typeError(translate("APP.VALIDATION.INVALID_VALUE", intl))
      .min(1, translate("APP.VALIDATION.INVALID_VALUE", intl))
      .required(translate("APP.VALIDATION.REQUIRED_FIELD", intl)),
  });

  const { trackingPayload } = useSelector(
    (state) => state.tracking,
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const jsonString = localStorage.getItem("selectedTest");
    const myObject = JSON.parse(jsonString);
    selectedTest.current = myObject;
    if (!myObject?.test_schedule_id) {
      history.replace("/");
    }
    return () => {
      if (imgRef.current) URL.revokeObjectURL(imgRef.current);
    };
  }, []);

  const handleSubmit = (values) => {
    const { wt_offered, allowed_variation } = selectedTest.current;
    const { product_pre_wt } = values;
    const pre_wt = Number(product_pre_wt);
    const weightMin = wt_offered - allowed_variation;
    const weightMax = wt_offered + allowed_variation;
    if (pre_wt > weightMax || pre_wt < weightMin) {
      toast.error(
        translate("TESTING.GENERAL.OFFERED_GREATER_THAN_PRODUCT", intl)
      );
      return;
    }
    imgRef.current = imageURL;

    values.test_schedule_id = selectedTest.current.test_schedule_id;
    values.language = intl.locale;
    values.species = selectedTest.current.species;
    values.panelist_id = selectedTest.current.panelist_id;
    values.product_id = selectedTest.current.product_id;

    var retry = 1;
    localStorage.setItem("preWeight", product_pre_wt);
    const api = () => {
      dispatch(uploadPreWeight(jsonHelper.jsonToForm(values)))
        .then(() => {
          toast.success(
            translate("TESTING.TOAST.WEIGHT_OFFERED_SUCCESS", intl)
          );
          dispatch(
            trackingLog({
              ...trackingPayload,
              start_image_url: imageURL,
              initial_weight: product_pre_wt,
              timer_page: 1,
            })
          );
          history.replace("/testing/timerstart");
        })
        .catch((error) => {
          dispatch(
            trackingLog({
              ...trackingPayload,
              api_log:
                error["stack"] +
                " | PreWeight " +
                format(new Date(), "yyyy-MM-dd HH:mm:ss") +
                " |" +
                error.message,
              start_image_url: imageURL,
              initial_weight: product_pre_wt,
              preweight_page: 1,
            })
          );

          retry++;
          if (retry < 3) {
            api();
          }
          if (retry === 3) setOpen(true);
          error.clientMessage = translate(
            "TESTING.TOAST.WEIGHT_OFFERED_FAILED",
            intl
          );
          dispatch(catchUIError(error));
        });
    };
    api();
  };
  const handleCapture = async (e, setFieldValue) => {
    if (e.target.files.length === 0) {
      setFieldValue("product_pre_img", "");
      if (imageURL) URL.revokeObjectURL(imageURL);
      setImageURL("");
      return;
    }

    const file = e.target.files[0];
    // console.log("originalFile instanceof Blob", file instanceof Blob); // true
    // console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: config.file.maxSizeMB,
      maxWidthOrHeight: config.file.maxWidthOrHeight,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      setFieldValue("product_pre_img", compressedFile);
      if (imageURL) URL.revokeObjectURL(imageURL);
      setImageURL(URL.createObjectURL(compressedFile));
      // console.log(file, compressedFile);
      setFileName(compressedFile.name);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="inner-page">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, handleSubmit, errors, touched }) => (
          <div className="test-box">
            <h3 className="test-box-title desktop-only">
              <i className="icon-capture"></i>{" "}
              {translate("TESTING.GENERAL.CAPTURE_WEIGHT_OFFERED", intl)}
            </h3>
            <div className="capture-wrap">
              {imageURL && (
                <div className="caputred-image-wrap">
                  <div className="caputred-image">
                    <img src={imageURL} alt="" />
                  </div>
                  <div className="caputred-image-info">
                    <span className="file-name">{fileName}</span>
                    <span className="reupload">
                      {translate("TESTING.GENERAL.RE_UPLOAD", intl)}
                      <i className="icon-reload"></i>
                      <input
                        name="product_pre_img"
                        type="file"
                        capture="environment"
                        accept="image/*"
                        onChange={(e) => handleCapture(e, setFieldValue)}
                      />
                    </span>
                  </div>
                </div>
              )}
              {!imageURL && (
                <div className="capture-inner">
                  <div className="c-left">
                    <h3 className="test-box-title mobile-only">
                      <i className="icon-capture"></i>{" "}
                      {translate("TESTING.GENERAL.CAPTURE_WEIGHT", intl)}
                    </h3>

                    <div className="upload text-center desktop-only">
                      <img src="/assets/images/upload.svg" alt="" />
                      <p>
                        {translate("TESTING.GENERAL.DRAN_AND_DROP", intl)}{" "}
                        <br />
                        {translate("APP.GENERAL.OR", intl)}
                      </p>
                      <button className="btn btn-outline-primary btn-h-40 use-camera">
                        {translate("TESTING.GENERAL.BROWSE", intl)}
                        <input
                          name="product_pre_img"
                          type="file"
                          capture="environment"
                          accept="image/*"
                          onChange={(e) => handleCapture(e, setFieldValue)}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="c-right">
                    <p>
                      {translate("TESTING.GENERAL.CLICK_PICTURE", intl)}{" "}
                      <strong>
                        {translate("TESTING.GENERAL.OFFERED_FOOD", intl)}
                      </strong>{" "}
                      {translate("TESTING.GENERAL.TO_THE_PET", intl)}
                    </p>
                    <div className="c-instruction">
                      <p>
                        {translate("TESTING.GENERAL.PICTURE_SHOULD_SHOW", intl)}{" "}
                        <br />
                        &nbsp; 1.{" "}
                        <strong>
                          {translate("TESTING.GENERAL.FOOD", intl)}
                        </strong>{" "}
                        {translate("TESTING.GENERAL.IN_THE_BOWL", intl)}
                        <br />
                        &nbsp; 2.{" "}
                        <strong>
                          {translate("TESTING.GENERAL.BOWL", intl)}
                        </strong>{" "}
                        {translate(
                          "TESTING.GENERAL.SHOULD_BE_ON_THE_SCALE",
                          intl
                        )}
                        <br />
                        &nbsp; 3.{" "}
                        <strong>
                          {translate("TESTING.GENERAL.WEIGHT", intl)}
                        </strong>{" "}
                        {translate(
                          "TESTING.GENERAL.CLEARLY_INDICATED_ON_THE_SCALE",
                          intl
                        )}
                      </p>
                    </div>
                    <button className="btn btn-outline-primary btn-h-40 btn-block use-camera mobile-only">
                      {translate("TESTING.GENERAL.USE_CAMERA", intl)}
                      <input
                        name="product_pre_img"
                        type="file"
                        capture="environment"
                        accept="image/*"
                        onChange={(e) => handleCapture(e, setFieldValue)}
                      />
                    </button>
                    {/* <button className="btn btn-outline-primary btn-h-40 btn-block mobile-only">
                      Use Camera
                    </button> */}
                  </div>
                </div>
              )}
              <ErrorMessage
                error={errors.product_pre_img}
                visible={touched.product_pre_img}
              />
            </div>
            <div className="form-group inline-label enter-weight">
              <label>
                {translate(
                  "TESTING.GENERAL.ENTER_THE_WEIGTH_FOOD_OFFERED",
                  intl
                )}
              </label>
              <div>
                <input
                  name="product_pre_wt"
                  type="number"
                  onWheel={(e) => {
                    e.currentTarget.blur();
                  }}
                  className="form-control"
                  placeholder="gms"
                  onChange={(e) =>
                    setFieldValue("product_pre_wt", e.target.value)
                  }
                />
                {
                  <ErrorMessage
                    error={errors.product_pre_wt}
                    visible={touched.product_pre_wt}
                  />
                }
                <div className="invalid-feedback" style={{ display: "none" }}>
                  ABC
                </div>
              </div>
            </div>
            <div className="text-center action-btn">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                onClick={handleSubmit}
              >
                {translate("TESTING.BUTTON.BEGIN_THE_TEST", intl)}
              </button>
            </div>
          </div>
        )}
      </Formik>
      <RedirectModel history={history} isOpen={open} />
    </div>
  );
}

export default injectIntl(PreWeight);
